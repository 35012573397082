<template>
    <div>
        <div class="shadow-lg bg-white rounded-lg flex items-center p-5">
            <el-form ref="form">
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-2">
                        <el-form-item class="mb-0" prop="name">
                            <label class="text-gray-400">
                                買受人 手機
                                <el-input size="small" clearable> </el-input>
                            </label>
                        </el-form-item>
                    </div>
                    <div class="col-span-2">
                        <el-form-item class="mb-0" prop="name">
                            <label class="text-gray-400">
                                統一編號
                                <el-input size="small" clearable> </el-input>
                            </label>
                        </el-form-item>
                    </div>
                    <div class="col-span-2">
                        <el-form-item class="mb-0" prop="role">
                            <label class="text-gray-400">
                                品名
                                <el-select :value="0" size="small" class="w-full" clearable>
                                    <el-option v-for="(status, index) in issueOptions" :key="index" :label="status.label" :value="status.value">
                                        {{ status.label }}
                                    </el-option>
                                </el-select>
                            </label>
                        </el-form-item>
                    </div>
                    <div class="col-span-3">
                        <el-form-item class="mb-0" prop="role">
                            <label class="text-gray-400">
                                開立日期 範圍
                                <el-date-picker
                                    size="small"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                                </el-date-picker>
                            </label>
                        </el-form-item>
                    </div>
                    <div class="col-span-3 flex items-end justify-center">
                        <button class="orange-btn-800 md:text-sm text-xs mr-1 w-[73px] h-[30px]" @click.prevent="onSubmit('form')">搜尋</button>
                        <button class="black-btn-outline md:text-sm text-xs w-[73px] h-[30px]" @click.prevent="resetData">重置</button>
                    </div>
                </div>
            </el-form>
        </div>
        <div class="action-bar bg-white rounded-lg mb-10 flex items-center px-5 justify-between">
            <el-checkbox>全選此頁資料</el-checkbox>
            <div>
                <button class="orange-btn-outline-800 md:text-sm text-xs h-[30px] mr-2" @click.prevent="resetData">列印所選資料</button>
            </div>
        </div>
        <LoadingComponent :isLoading="loading" />
        <div class="overflow-x-auto">
            <table class="myTable bg-white">
                <thead>
                    <tr>
                        <th class="w-[20px] checkbox-th"></th>
                        <th>發票號碼</th>
                        <th>買受人</th>
                        <th>統一編號</th>
                        <th>品名</th>
                        <th>金額</th>
                        <th>開立時間</th>
                    </tr>
                </thead>
                <!-- <tbody>
                    <tr v-for="(item,index) in datas"
                        :key="index"
                        class="text-center duration-200 transition">
                        <td>{{ $subString(item.name, 10) }}</td>
                        <td>{{ !$isEmpty(item.real_name) ? $subString(item.real_name,10) : item.real_name }}</td>
                        <td>{{ item.birthday }}</td>
                        <td>{{ item.phone }}</td>
                        <td>{{ item.government_id }}</td>
                        <td>{{ item.rating_score }}</td>
                        <td :class="item.role === 1 ? 'text-black' : 'text-red-500'">{{ item.role | showRoleText }}</td>
                        <td :class="item.status === 0 ? 'text-black' : 'text-red-500'">{{ item.status | showStatusText }}</td>
                        <td class="cursor-pointer"
                            @click="goToEdit(item.id)">
                            <i class="far fa-edit"></i>
                        </td>
                    </tr>
                </tbody> -->
            </table>
        </div>
        <!-- <div class="justify-center mt-10 flex">
            <MyPagination :paginationData="paginationData"
                          :currentPage="currentPage"
                          @onPageSizeChange="pageSizeChange"
                          @onCurrentPageChange="currentPageChange" />
        </div> -->
    </div>
</template>

<script>
import LoadingComponent from "@/components/Loading.vue";

export default {
    name: "SubmitApplication",
    components: {
        LoadingComponent,
    },
    data() {
        return {
            loading: false,
            issueOptions: [
                {
                    label: "不限",
                    value: 0,
                },
                {
                    label: "立即提領",
                    value: 1,
                },
                {
                    label: "月結款項",
                    value: 2,
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.input-group {
    .label {
        color: #757575;
        font-size: 12px;
        font-weight: 400;
    }
}

.action-bar {
    background: white;
    color: chocolate;
    margin: 10px 0;
    height: 55px;
}

.checkbox-th {
    min-width: auto;
}

::v-deep .el-form-item__content {
    line-height: 30px;
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}
</style>
